.summaryApp {
    width: 100%;
    background-color: #e5ebed !important;
}
.watermark {
    font-weight: bold;
    font-size: 0.7rem;
    color: #a3a8aa;
}
.centerContent {
    text-align: center;
    position: absolute !important;
    top: 50%;
}
