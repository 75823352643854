.warningColor {
    color: #cc0000;
}
.bedroomBox {
    margin: 15px 0;
}
.titleBedroom {
    font-size: 0.9rem;
    margin: 0 0 5px 0;
    display: block;
}
.listBeds {
    list-style: none;
    padding: 0;
    font-size: 0.8rem;
}
