.roomItem {
    background-color: #f6f7f8;
    margin: 20px 0;
    border-radius: 15px;
    border: 1px solid #ccd6db;
    padding: 15px;
}
.roomItem.lock {
    background-color: #ffffff;
}
