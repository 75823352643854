.btn {
    padding: 10px 20px;
}
.primary {
    background-color: #027eca;
    border-color: #027eca;
}
.light {
    background-color: #ffffff;
    border-color: #ccd6db;
}
.white,
.white:hover {
    color: #ffffff;
}
.red,
.red:hover {
    color: #cc0000;
}
.black,
.black:hover {
    color: #000000;
}
.fa-spin {
    animation: fa-spin 2s infinite linear;
}
@keyframes fa-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}
