body {
    margin: 0;
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    font-size: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #655655;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
h1,
h2,
h3,
h4 {
    font-weight: bold;
    color: #655655;
}
h2,
.h2 {
    font-size: 1.6rem;
}
