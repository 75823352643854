.App-link {
    color: #61dafb;
}
.btn.focus,
.btn:focus {
    box-shadow: none !important;
}
.left {
    float: left;
}
.right {
    float: right;
}
.show {
    display: block;
}
.hide {
    display: none;
}
.container .title,
.container .title .info {
    text-align: center;
}
.content-items,
.content-footer {
    display: block;
    overflow: hidden;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
